import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Button } from 'react-bootstrap';
import CustomDatePicker from '../../../../components/CreatePosting/CustomDatePicker';
import useMobile from '../../../../hooks/useMobile';
const FiltersSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setTemporaryJobs,
  gridWidth,
  postingTitle,
  setPostingTitle,
  locationFilter,
  setLocationFilter,
  status,
  setStatus,
  startDate,
  setStartDate,
  handleFilterData,
  resetFilter,
}) => {
  const authToken = localStorage.getItem('auth_token');
  const isMobile = useMobile(767);
  return (
    <Drawer
      style={{ width: isMobile ? '80%' : 340 }}
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      PaperProps={{
        style: {
          width: isMobile ? '80%' : 340,
          padding: '16px',
          gap: '1rem',
          overflow: 'auto',
        },
      }}
    >
      <Typography variant="h6" component="h2">
        Filters
      </Typography>
      <IconButton onClick={() => setIsSidebarOpen(false)}>
        <CloseIcon
          style={{
            marginLeft: '220px',
            position: 'absolute',
            top: '-47px',
          }}
        />
      </IconButton>
      <TextField
        variant="outlined"
        fullWidth
        label="Posting Title"
        id="postingTitle"
        value={postingTitle}
        onChange={(e) => setPostingTitle(e.target.value)}
        InputProps={{
          style: {
            textAlign: 'center',
            padding: '10px',
          },
        }}
      />

      <TextField
        variant="outlined"
        fullWidth
        label="Location"
        id="location"
        value={locationFilter}
        onChange={(e) => setLocationFilter(e.target.value)}
        InputProps={{
          style: {
            textAlign: 'center',
            padding: '10px',
          },
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={(e) => setStatus(e.target.value)}
          style={{
            textAlign: 'center',
            padding: '10px',
          }}
        >
          <MenuItem
            value="new"
            style={{
              display: 'block',
              paddingLeft: '15px',
              margin: '5px',
            }}
          >
            New
          </MenuItem>
          <MenuItem
            value="active"
            style={{
              display: 'block',
              paddingLeft: '15px',
              margin: '5px',
            }}
          >
            Active
          </MenuItem>
          <MenuItem
            value="cancelled"
            style={{
              display: 'block',
              paddingLeft: '15px',
              margin: '5px',
            }}
          >
            Cancelled
          </MenuItem>
        </Select>
      </FormControl>

      <CustomDatePicker
        label="Start Date"
        value={startDate}
        onChange={(date) => setStartDate(date)}
        size="medium"
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Button
          variant="outline-primary"
          style={{
            width: '100%',
            border: '1px solid #2561B0',
            color: '#595959',
          }}
          onClick={resetFilter}
        >
          Reset
        </Button>
        <Button
          variant="primary"
          style={{
            width: '100%',
            backgroundColor: '#2561B0',
            border: 0,
          }}
          onClick={handleFilterData}
        >
          Find
        </Button>
      </div>
    </Drawer>
  );
};

export default FiltersSidebar;
