import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from '@mui/icons-material/Search';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import { selectUser, setUser } from '../../redux/slices/userSlice';
import { API_BASE_URL } from '../../config/constants';

import Pusher from 'pusher-js';
import {
  setNotification,
  addNotification,
  getNotificationsStatus,
  fetchNotifications,
  selectAllNotifications,
} from '../../redux/slices/notificationSlice';

import { setAuthToken, setIsloggedin } from '../../redux/slices/authSlice';
import {
  selectAuthToken,
  selectIsloggedin,
} from '../../redux/slices/authSlice';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import Loader from '../General/Loader';
import { setLoading } from '../../redux/reducer/loadingReducer';
import NotificationsSidebar from '../../pages/Owner/Notifications';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import HamburgerIcon from '../Global/HamburgerIcon';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const initPusher = () => {
  const pusher = new Pusher('9327abb9d05e103ead60', {
    cluster: 'mt1',
  });
  return pusher;
};

const useChannel = (channelName) => {
  const pusher = initPusher();
  const channel = useMemo(() => {
    if (!channelName) return null;
    if (!pusher.channel(channelName)) {
      return pusher.subscribe(channelName);
    }
    return pusher.channel(channelName);
  }, [channelName]);

  return channel;
};
export default function NavbarOwner({ isSidebarOpen, setIsSidebarOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentUser = useSelector(selectUser);
  const loading = useSelector((state) => state.loading);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const notificationsStatus = useSelector(getNotificationsStatus);
  const notifications = useSelector(selectAllNotifications);
  const [notificationsSidebar, setNotificationsSidebar] = React.useState(false);

  // const channel = useChannel(currentUser?.socket_channel);

  // useEffect(() => {
  //   if (notificationsStatus === 'idle') {
  //     dispatch(fetchNotifications());
  //   }
  // }, [notificationsStatus, dispatch, notificationsSidebar]);

  // useEffect(() => {
  //   const pusher = new Pusher('9327abb9d05e103ead60', {
  //     cluster: 'mt1',
  //   });
  //   const channel = pusher.channel(currentUser?.socket_channel) || pusher.subscribe(currentUser?.socket_channel);
  //   // channel.bind('notification', function (data) {
  //   //   dispatch(addNotification(data));
  //   // });
  //   return (() => {
  //     pusher.unsubscribe(currentUser?.socket_channel)
  //   })
  // }, []);

  // useEffect(() => {
  //   channel.bind('notification', function (data) {
  //     dispatch(addNotification(data));
  //   });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       'https://api.mddentalstaffing.com/api/v1/notifications/unread-count',
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setUnreadNotifications(response.data.data);
  //     }).catch((e) => {
  //       console.log('error x=== >', e);
  //     });
  // }, [unreadNotifications]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async () => {
    dispatch(setLoading(true));

    try {
      dispatch(setUser({}));
      dispatch(setIsloggedin(false));
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');

      navigate('/login');
      window.location.reload();
    } catch (error) {
      console.error('An error occurred while clearing local storage:', error);
    } finally {
      // dispatch(setLoading(false));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    if (user.role_type === 'owner') {
      navigate('/owner/account/profile');
    } else {
      navigate('/professional/account/profile');
    }
    // setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate('/messages');
          handleMobileMenuClose();
        }}
      >
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="lightblue">
            <SmsOutlinedIcon sx={{ color: '#262626' }} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setNotificationsSidebar(true);
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={unreadNotifications} color="sunset">
            <NotificationsNoneIcon sx={{ color: '#262626' }} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  if (loading.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #D9D9D9',
            boxShadow: 'none',
            border: 0,
          }}
        >
          <Toolbar className="ps-0">
            <HamburgerIcon
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <img className="w-75" src={logo} alt="Logo" />
            </Typography>
            <Search sx={{ backgroundColor: '#E8E8E8', color: '#8C8C8C' }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box
                sx={{
                  borderLeft: '1px solid #D9D9D9',
                  boxShadow: 'none',
                  py: 1,
                  pl: 1,
                  mx: 1,
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={() => navigate('/messages')}
                >
                  <Badge badgeContent={4} color="lightblue">
                    <SmsOutlinedIcon sx={{ color: '#262626' }} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="show new notifications"
                  onClick={() => setNotificationsSidebar(true)}
                >
                  <Badge badgeContent={unreadNotifications} color="sunset">
                    <NotificationsNoneIcon sx={{ color: '#262626' }} />
                  </Badge>
                </IconButton>
              </Box>
              <Box
                sx={{
                  borderLeft: '1px solid #D9D9D9',
                  boxShadow: 'none',
                  display: 'flex',
                  py: 1,
                  px: 1,
                  mx: 1,
                }}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {user.avatar ? (
                    <Avatar
                      alt="Remy Sharp"
                      src={`${API_BASE_URL}/assets/${user.avatar}`}
                      sx={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <AccountCircle sx={{ color: '#262626' }} />
                  )}
                </IconButton>

                <div className="d-inline mx-4">
                  <div className="d-grid p-0 m-0">
                    <p
                      className="m-0 p-0"
                      style={{ color: '#8C8C8C', fontSize: '0.7rem' }}
                    >
                      HELLO,
                    </p>
                    <h6
                      className="fw-semibold m-0 p-0"
                      style={{ color: '#262626', fontSize: '1.3rem' }}
                    >
                      {user && user.title} {user && user.first_name}{' '}
                      {user && user.last_name}
                    </h6>
                  </div>
                </div>
              </Box>

              <IconButton
                size="large"
                aria-label="logout"
                onClick={handleLogout}
              >
                <LoginOutlinedIcon sx={{ color: '#262626' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <NotificationsSidebar
        handleClose={() => setNotificationsSidebar(false)}
        open={notificationsSidebar}
        key={notificationsSidebar}
        notifications={notifications}
      />
    </>
  );
}
